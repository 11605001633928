<template>
  <div :class="['controls', {live: isLive, 'live-time-shift': isLiveTimeShift}]">
    <button class="back" title="20 Sekunden rückwärts springen [AK+J]" accesskey="j" :disabled="isLive && !liveTimeshiftAvailable" @click="goBackwards">
      <svg class="icon icon-back20"><use xlink:href="#icon-back20"></use></svg>
    </button>
    <button :class="['toggle', {paused: paused, playing: !paused}]" :title="(paused ? 'Abspielen' : 'Anhalten') + ' [AK+K]'" accesskey="k" @click="onTogglePlay">
      <LoadingView class="loadingView" v-if="loading && !paused" />
      <svg class="icon" v-else>
        <use xlink:href="#icon-play" class="play"></use>
        <use xlink:href="#icon-pause" class="pause"></use>
        <use xlink:href="#icon-stop" class="stop"></use>
      </svg>
    </button>
    <button class="forward" title="20 Sekunden vorwärts springen [AK+L]" accesskey="l" :disabled="isLive && (!isLiveTimeShift || !liveTimeshiftAvailable)" @click="goForward">
      <svg class="icon icon-forward20"><use xlink:href="#icon-forward20"></use></svg>
    </button>
    <span :class="['volume', 'volume-' + Math.ceil(volume*4)*25]" :style="{'visibility': isVolumeControllable ? 'visible' : 'hidden'}">
      <button class="mute-toggle" title="Stummschalten [AK+M]" accesskey="m" @click="onToggleMute">
        <span class="current-volume">
          <span class="volume-bar-25"></span>
          <span class="volume-bar-50"></span>
          <span class="volume-bar-75"></span>
          <span class="volume-bar-100"></span>
        </span>
      </button>
      <span class="volume-slider">
        <input type="range" class="volume-input" min="0" max="1" step="0.1" v-model.number="volume" aria-label="Lautstärke" />
      </span>
    </span>
    <audio ref="audio" @play="paused=false" @pause="paused=true" @ended="onEnded" @timeupdate="onTimeUpdate" @canplaythrough="loading=false" @volumechange="volume=$event.target.volume" :preload="isLive || !autoplay ? 'none' : 'auto'"></audio>
  </div>
</template>

<script>
const Cookies = require("js-cookie/src/js.cookie.js");
import { v4 as uuid } from 'uuid';
import config from "../config";
import {convertToLocalTime} from "../utils";
import LoadingView from "./LoadingView"
import eventBus from "../eventbus"
import { format as formatDate } from 'date-fns'

const LOCALSTORAGE_VOLUME_KEY = "oon-radiothek-timelineplayer-volume"

export default {
  name: 'AudioPlayer',
  props: {
    broadcast: Object,
    isLive: Boolean,
    cue: Number, //jump time
    markOut: Number, // stop time
    endTime: Number,
    autoplay: Boolean,
    livestreamURL: String,
    livestreamQuality: String, // just for watching changes
    loopstreamHost: String,
    loopstreamChannel: String,
    isLiveTimeShift: Boolean,
    timelineStart: Number,
    liveTimeshiftAvailable: Boolean,
  },
  components: {
    LoadingView,
  },
  watch: {
    paused(newVal, prevVal) {
      // console.log("paused changed to", newVal)
      this.$emit('paused', newVal)
      if (newVal) {
        if (this.isLive && !this.isLiveTimeShift) {
          // maybe kill source only if pause clicked, not media control center keys pause
          const audio = this.$refs.audio
          //https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Using_HTML5_audio_and_video#Stopping_the_download_of_media
          audio.removeAttribute("src")
          audio.load();
          // console.log("paused live, discard src")
          //more info: http://stackoverflow.com/questions/4071872/html5-video-force-abort-of-buffering/13302599#13302599
        }
      } else {

      }
    },
    livestreamQuality(n, o) {
      console.log("livestream quality changed", n, o)
      if (this.isLive && !this.isLiveTimeShift) {
        this.forceAutoplay = !this.paused;
        this.src = this.composeLivestreamURL()
      }
    },
    volume(newVal) {
      try {
        localStorage.setItem(LOCALSTORAGE_VOLUME_KEY, newVal)
      } catch (error) {
        console.warn("error setting localStorage", error)        
      }
      this.$refs.audio.volume = newVal
    },
    src(newVal) {
      this.setAudioSrc()
    },
    // we could also listen to all props change: 
    /*
    watch: {
      $props: {
      }
    }
    */
    propDigest: {
      immediate: true, // needed to call on first value / initial
      handler(newVal, oldVal) {
        this.currentStream = null;
        this.forceAutoplay = this.autoplay && !this.preventAutoplay;
        this.preventAutoplay = false;
        if (this.isLive) {
          if (!this.isLiveTimeShift) {
            this.src = this.composeLivestreamURL();
          } else {
            // do nothing, handle by jumpToTime
          }
        } else {
          this.jumpToTime(this.cue, this.forceAutoplay)
        }
        if ('mediaSession' in navigator) {
          navigator.mediaSession.setActionHandler('seekbackward', this.isLive && !this.isLiveTimeShift ? null : this.goBackwards);
          navigator.mediaSession.setActionHandler('seekforward', this.isLive && !this.isLiveTimeShift ? null : this.goForward);
        }
      }
    }
  },
  methods: {

    setAudioSrc() {
      if (!this.src) console.warn("setting empty src");
      if (!this.forceAutoplay) {
        this.paused = true
      }
      // console.log("setting src to", this.src, "forceAutoplay?", this.forceAutoplay, "preload attr:", this.$refs.audio.preload)
      this.$refs.audio.setAttribute("src", this.src);

      // parse seekOffset from media fragment url
      const anchor = document.createElement("a")
      anchor.href = this.src
      const seekOffset = +anchor.hash.replace("#t=", "")

      // TODO is it even necessary to call load() at all??
      console.debug("calling load, preload:", this.$refs.audio.preload, "currentSrc", this.$refs.audio.currentSrc);
      if (this.$refs.audio.currentSrc && this.$refs.audio.currentSrc !== this.src) {
        // console.debug("changed src, trigger load")
        this.$refs.audio.load(); // this triggers reset, should honor preload; but doesn't in chrome/ff; so only call when necessary
      }
      if (!this.isLive || this.isLiveTimeShift) {
        // media fragment "#t=" works in safari, but chrome sends timeupdate:0 before seeking in pd mp3; so seek aditionally
        // ie also wouldnt support media fragment
        if (this.seekInStream && !this.useHLSForLoopstream) {
          // immediately seeking throws invalidstateerror in ie 11; would need to seek in loadmetadata or loadeddata
          // we can not use it nonetheless ATM because ie and edge 18 don't seek via byte-range, but load whole audio from start
          // but byte-range seekings works with apache, so probably loopstreamer header wrong
          this.$refs.audio.currentTime = seekOffset
        }
      }

      this.loading = true
      if (this.forceAutoplay) {
        // console.log("try play")
        let playPromise = this.$refs.audio.play();
        if (playPromise) playPromise.catch(error => console.warn('cannot play', error));
      }
      this.forceAutoplay = false
      if (!this.isLive || this.isLiveTimeShift) {
        this.$emit("progress", this.currentStreamStartTime + seekOffset * 1000)
      }
    },
    start() {
      const audio = this.$refs.audio
      // src should be reactive/prop
      if (!audio.src) {
        this.forceAutoplay = true
        this.setAudioSrc()
      } else {
        let playPromise = audio.play();
        if (playPromise) playPromise.catch(error => console.warn('cannot play from start()', error));
      }
    },
    pause() {
      this.$refs.audio.pause();
      // explicitly set, because before source change it doesnt seem to be triggered sometimes
      this.paused = true
    },
    onTogglePlay() {
      const audio = this.$refs.audio
      if (audio.paused) {
        this.start();
      } else {
        this.pause();
      }
    },
    onEnded() {
      if (!this.isLive) {
        if (this.nextMarkInTime) {
          console.log("onEnded markout reached, skip to next markin");
          this.jumpToTime(this.nextMarkInTime, true);
        } else {
          console.log("onEnded no more markIn, stopping player");
          this.$emit("endReached")
        }
      } else {
        console.warn("ended, but was live?!")
      }
    },
    getCurrentPosition() {
        return this.currentStreamStartTime + Math.round(this.$refs.audio.currentTime*1000)
    },
    onTimeUpdate(event) {
      const audio = this.$refs.audio; // should be the same as event.target; disappears while unmounting
      if (!audio) {
        // can happen while unmounting
        console.warn("audio el disappeared from component, ignore timeupdate");
        return
      }
      if (audio.currentTime > 0) {
        this.loading = false;
      }
      console.assert(audio == this.$refs.audio);
      // console.log("timeupdate", audio.currentTime)
      if (!this.isLive) {
        let position = this.getCurrentPosition();
        if (position > this.markOut && position < this.markOut+1000) {
          if (!audio.paused) {
            console.log("player markOut reached, pause")
            audio.pause()
            this.$emit("markOutReached")
          }
        } else if (position >= this.nextMarkOutTime) {
          if (this.nextMarkInTime && this.nextMarkInTime > position && this.nextMarkInTime < this.endTime) {
            console.debug("onTimeUpdate markout reached, skip to next markin");
            this.jumpToTime(this.nextMarkInTime, true);
          } else {
            if (!audio.paused) {
              console.log("onTimeUpdate markout reached, no more markIn, stopping player, more data: " + (audio.duration - audio.currentTime));
              audio.pause()
              // audio shouldn't be longer, but ignore 2 * 20 (chunk size) overflow
              console.assert(audio.duration - audio.currentTime < 41);
              this.$emit("endReached")
            }
          }
        } else {
          this.$emit("progress", position)
        }
        if (!this.currentStream) {
          //TODO what happens? better set one, even if wrong?
          console.warn('no current stream?');
        }
      } else if (this.isLiveTimeShift) {
        this.$emit("progress", this.getCurrentPosition())
      }
    },
    printSomething(msg) {
      console.log("from player:" + msg)
    },
    jumpToTime(offset, forceAutoplay) {
      console.assert(offset)
      // console.log("jumpToTime " + offset + ' = ' + new Date(offset));
      if (this.isLive) {
        if (this.isLiveTimeShift && this.seekInStream) {
          // console.log("seekInStream and already in timeshift, just seek");
          const audio = this.$refs.audio
          if (this.useHLSForLoopstream) console.assert(audio.seekable.length > 0, "when using hls, stream should be loaded and seekable in safari");
          audio.currentTime = offset / 1000 - this.currentStreamStartTime / 1000;
          if (forceAutoplay) {
            // console.log("calling play after seeking timeshift hls")
            let playPromise = audio.play();
            if (playPromise) playPromise.catch(error => console.warn('cannot play after seeking', error));
          }
          this.$emit("progress", offset);
        } else {
          // console.log("setting timeshift")
          this.$parent.isLiveTimeShift = true;
          if (this.seekInStream) {
            this.currentStreamStartTime = this.timelineStart
          } else {
            this.currentStreamStartTime = offset
          }
          this.forceAutoplay = forceAutoplay
          this.src = this.composeLoopstreamerTimeShiftURL(offset);
        }
        return;
      }

      if (isNaN(offset) || offset < this.startTime) {
        console.warn('offset is lower than start time');
        offset = this.startTime;
      } else if (offset >= this.endTime) {
        console.warn('offset is higher than start time');
        offset = this.endTime-1;
      }

      var newStream = null;
      var nextStream = null;
      this.streams.forEach( function findStreamInRange(stream) {
        if (offset >= stream.start && offset <= stream.end) {
          console.debug('found ' + stream.loopStreamId);
          newStream = stream;
        } else if (offset >= stream.start) {
          if (this.streams.indexOf(stream) < this.streams.length-1)
          nextStream = this.streams[this.streams.indexOf(stream)+1];
        }
      }, this);
      if (newStream === null) {
        if (nextStream !== null) {
          newStream = nextStream;
          console.debug("taking next stream");
        } else if (this.streams[0].start > this.startTime) {
          console.debug('first stream starts after broadcast start');
          newStream = this.streams[0];
        } else {
          throw new Error("found no stream, offset: " + new Date(offset) + "; streams: " + JSON.stringify(this.streams));
        }
      }

      const cue = Math.max(0, offset-newStream.start);

      var nextMarkOut = this.findNextMarkOfType("out", newStream.start + cue);
      this.nextMarkOutTime = nextMarkOut ? Math.min(nextMarkOut.timestamp, this.endTime) : this.endTime;
      var nextMarkIn = this.findNextMarkOfType("in", this.nextMarkOutTime);
      this.nextMarkInTime = nextMarkIn ? nextMarkIn.timestamp : null;
      if (this.nextMarkInTime && this.nextMarkOutTime) {
        if (this.nextMarkInTime <= this.nextMarkOutTime) {
          console.warn('markIn > markOut, ignore');
          this.nextMarkInTime = null;
        }
      }

      if (this.seekInStream) {
        if (this.currentStream != newStream) {
          console.log("switch stream")
          this.forceAutoplay = forceAutoplay
          this.currentStream = newStream;
          this.currentStreamStartTime = newStream.start
          this.src = this.composeLoopStreamOnDemandURL(newStream.loopStreamId, cue);
        } else {
          console.log("reuse old stream, just seek to " + cue / 1000)
          const audio = this.$refs.audio
          if (this.useHLSForLoopstream) console.assert(audio.seekable.length > 0, "when using hls, stream should be loaded and seekable in safari");
          audio.currentTime = cue / 1000;
          if (forceAutoplay) {
            console.log("calling play after seeking")
            let playPromise = audio.play();
            if (playPromise) playPromise.catch(error => console.warn('cannot play after seeking', error));
          }
          this.$emit("progress", this.currentStream.start + cue)
        }
      } else {
        this.forceAutoplay = forceAutoplay
        this.currentStream = newStream;
        this.currentStreamStartTime = newStream.start + cue
        this.src = this.composeLoopStreamOnDemandURL(newStream.loopStreamId, cue);
      }
    },
    getLoopstreamerBaseURL() {
      var loopStreamerBaseURL = "//" + this.loopstreamHost + "/" + 
        (this.useHLSForLoopstream ? "playlist.m3u8" : "") + "?channel=" + this.loopstreamChannel +
        "&shoutcast=0&player=radiothek_v1&referer=" + location.hostname + "&_=" + Date.now();
      //would not be necessary to do this every time
      const sessionCookieId = Cookies.get(config.CLIENT_SESSION_ID_KEY);
      if (sessionCookieId) {
        loopStreamerBaseURL += "&userid=" + sessionCookieId;
      } else {
        console.warn("no session cookie found, cookies disabled or wrong domain?");
      }
      return loopStreamerBaseURL;
    },
    composeLoopStreamOnDemandURL(loopStreamID, offset) {
      if (this.seekInStream && offset > 0) {
        console.warn("we seek in stream, so offset should be 0");
      }
      const loopStreamerBaseURL = this.getLoopstreamerBaseURL();
      const croppedStreamEndMs = (Math.min(this.currentStream.end, this.endTime) - this.currentStream.start) 
      var loopstreamerURL = loopStreamerBaseURL + "&id=" + loopStreamID;
      if (this.seekInStream) {
        if (offset === 0) {
          loopstreamerURL += "#t=0.001"; // "0" not working in safari (at least with #EXT-X-PLAYLIST-TYPE:EVENT)
        } else {
          loopstreamerURL += "#t=" + offset/1000;
        }
        console.log("seek in stream stream");
      } else {
        loopstreamerURL += "&offset=" + Math.round(offset || 0) + "&offsetende=" + Math.round(croppedStreamEndMs);
      }
      return loopstreamerURL;
    },
    composeLoopstreamerTimeShiftURL(offset) {
      const loopStreamerBaseURL = this.getLoopstreamerBaseURL();
      const cueDate = new Date(offset);
      var loopstreamerURL = loopStreamerBaseURL;
      const endDate = new Date(this.endTime) // loopstreamer has default end in 1h, so make it end at midnight
      // TODO maybe use import {utcToZonedTime} from "date-fns-tz"
      // or use Intl.DateTimeFormat to format (setHours(24) would be wrong for different timeZone, but shouldn't matter)
      const localEndDate = convertToLocalTime(endDate, this.broadcast.endOffset);
      localEndDate.setHours(24)
      localEndDate.setMinutes(0)
      localEndDate.setSeconds(0)
      loopstreamerURL += "&ende=" + formatDate(localEndDate, 'yyyyMMdd')
      if (this.seekInStream) {
        const localStartDate = convertToLocalTime(this.timelineStart, this.broadcast.endOffset)
        loopstreamerURL += "&start=" + formatDate(localStartDate, 'yyyyMMddHHmmss')
        let fragmentCue = (cueDate.getTime() - this.timelineStart)/1000
        // console.log("fragment", fragmentCue)
        loopstreamerURL += "#t=" + fragmentCue;
      } else {
        const localCueDate = convertToLocalTime(cueDate, this.broadcast.endOffset)
        loopstreamerURL += "&start=" + formatDate(localCueDate, 'yyyyMMddHHmmss');
      }
      // https://loopstream01.apa.at/?channel=oe1&start=20191014134000
      return loopstreamerURL;
    },
    composeLivestreamURL() {
      const sessionCookieId = Cookies.get(config.CLIENT_SESSION_ID_KEY);
      var livestreamURL = this.livestreamURL;
      if (livestreamURL.indexOf("sf.apa.at") === -1) {
        livestreamURL += "?player=radiothek_v1&referer=" + location.hostname;
        if (sessionCookieId) {
          livestreamURL += "&userid=" + sessionCookieId;
        } else {
          console.warn("no session cookie found, cookies disabled or wrong domain?");
        }
      }
      return livestreamURL
    },
    findNextMarkOfType(type, timestamp) {
      return this.marks.find(m => {
        return m["type"] === type && m.timestamp > timestamp;
      });
    },
    goForward() {
      this.$parent.shouldUpdateHandle = true
      this.jumpBy(20);
    },
    goBackwards() {
      this.$parent.shouldUpdateHandle = true
      if (this.isLive && !this.isLiveTimeShift) {
        this.jumpBy(-config.LOOPSTREAMER_READY_DELAY/1000)
      } else {
        this.jumpBy(-20);
      }
    },
    jumpBy(secs) {
      if (this.isLive) {
        if (this.isLiveTimeShift) {
          const target = this.getCurrentPosition() + secs*1000
          if (target > this.$parent.getLiveTimePosition() - config.LOOPSTREAMER_READY_DELAY) {
            console.log("go back to live")
            this.forceAutoplay = true;
            this.$parent.isLiveTimeShift = false
          } else {
            this.jumpToTime(Math.max(this.timelineStart, target), true);
          }
        } else {
          this.jumpToTime(this.$parent.getLiveTimePosition() + secs*1000, true);
          this.$parent.isLiveTimeShift = true
        }
      } else {
        this.jumpToTime(Math.max(this.startTime, this.getCurrentPosition() + secs*1000), true);
      }
    },
    onToggleMute() {
      if (this.volume === 0) {
        if (this.previousVolume) {
          this.volume = this.previousVolume
          this.previousVolume = null;
        } else {
          this.volume = 1
        }
      } else {
        this.previousVolume = this.volume;
        this.volume = 0
      }
    },
    onCommand({command, ...options}) {
      switch (command) {
        case "play":
          return this.start();
        case "pause":
          return this.pause();
        case "toggle":
          return this.onTogglePlay();
        default:
          console.warn("Unknown player command", command);
      }
    },
    onKeyDown(event) {
      if (!["input", "textarea", "button", "select"].includes(event.target.tagName.toLowerCase())) {
        if (event.code === "Space") {
          event.preventDefault();
          this.onTogglePlay();
        } else if (event.code === "ArrowLeft") {
          event.preventDefault();
          this.goBackwards();
        } else if (event.code === "ArrowRight" && (!this.isLive || this.isLiveTimeShift)) {
          event.preventDefault();
          this.goForward();
        }
      }
    }
  },
  computed: {
    // paused() {
    //   return this.$refs.audio && this.$refs.audio.paused
    // }
    startTime() {
      return this.broadcast.start
    },
    streams() {
      return this.broadcast.streams
    },
    marks() {
      return this.broadcast.marks
    },
    //combination of all relevant props to reload player
    propDigest() {
      return [this.broadcast.programKey, this.broadcast.broadcastDay, this.cue, this.isLive ? "live" : "od", this.isLiveTimeShift ? "timeshift" : undefined].join("-")
    },

  },
  beforeCreate() {
    if (!Cookies.get(config.CLIENT_SESSION_ID_KEY)) {
      // console.log("creating radiothek session id");
      Cookies.set(config.CLIENT_SESSION_ID_KEY, uuid(), { domain: '.orf.at' });
    }
  },
  beforeDestroy() {
    eventBus.$off("player", this.onCommand);
    document.removeEventListener("keydown", this.onKeyDown);
  },
  mounted() {
    try {
      const savedVolumeValue = localStorage.getItem(LOCALSTORAGE_VOLUME_KEY)
      const savedVolumeParsed = savedVolumeValue ? parseFloat(savedVolumeValue) : null;
      if (savedVolumeParsed !== null && !isNaN(savedVolumeParsed)) {
        this.$refs.audio.volume = savedVolumeParsed;
      }
    } catch (error) {
      console.warn("error reading localStorage", error)
    }
    /* hide volume control if volume change not possible, this is the case on iOS */
    const oldVolume = this.$refs.audio.volume;
    const testVolume = oldVolume > 0.5 ? oldVolume - 0.1 : oldVolume + 0.1;
    this.$refs.audio.volume = testVolume;
    eventBus.$on("player", this.onCommand);
    if ('mediaSession' in navigator) {
      // only used in chrome, but also on desktop
      navigator.mediaSession.setActionHandler('play', this.start);
      navigator.mediaSession.setActionHandler('pause', this.pause);
      // back/forward is only set for nonlive
    }
    requestAnimationFrame(() => {
      if (this.$refs.audio.volume !== testVolume) {
        console.log('volume change not possible, hide volume control');
        this.isVolumeControllable = false
      } else {
        this.$refs.audio.volume = oldVolume;
      }
      this.volume = this.$refs.audio.volume
    });
    document.addEventListener("keydown", this.onKeyDown, false);
  },
  data() {
    const USE_HLS_FOR_LOOPSTREAM = navigator.vendor === "Apple Computer, Inc.";
    return {
      paused: true, //maybe move to store, because parent needs it too? triggering events in watcher is weird. or use this.$parent.paused?
      volume: 1,
      previousVolume: 0,
      isVolumeControllable: true,
      currentStream: null,
      currentStreamStartTime: 0, // audio.currentTime === 0 as javascript datetime
      nextMarkOutTime: 0,
      nextMarkInTime: 0,
      src: null,
      loading: false,
      forceAutoplay: false, // for programmatic jumps by buttons/mouse
      preventAutoplay: false, // prevent autoplay on stream change even if autoplay==true
      useHLSForLoopstream: USE_HLS_FOR_LOOPSTREAM,
      seekInStream: USE_HLS_FOR_LOOPSTREAM, // would works also nice in chrome and ff with cbr stream, but not with ie or edge
    }
  }
}
</script>

<style lang="postcss" scoped>

@import "../styles/mixins.css";
@import "../styles/vars.css";

:root {
  --slider-track-color: #5d5d5d; /* from fm4; oe1: #a5a5a5 */
  --button-width: 40px;
  --button-spacing: 12px;
}

.controls {
  margin: 0;
  overflow: visible;
  padding-top: 5px;
  padding-bottom: 4px;
  --offset: calc((3 * var(--button-width) + 2 * var(--button-spacing)) / 2);
  padding-left: calc(50% - var(--offset));
}
.controls::after {
  content: "";
  display: block;
  clear: both;
}

.controls.live {
}
.controls button {
  text-align: center;
  display: block;
  float: left;
  width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center;
  border: 0 none;
  border-radius: 0;
  @mixin theme {
    &:not(:disabled) {
      color: var(--controls-button-color);
    }
    &:not(.toggle):not(:disabled) {
      color: white;
      &:hover {
        color: var(--controls-button-color);
        @media (hover: none) {
          color: white;
        }
      }
    }
  }
  background-color: transparent;
  font-size: 16px;
  padding: 0;
  position: relative;
}
.controls button:not(:disabled):hover {
  background-color: var(--controls-background-hover-color);
  @media (hover: none) {
    background-color: transparent;
  }
  cursor: pointer;
}
.controls button:disabled {
  color: #5d5d5d; /* from fm4; oe1: #6e6e6e */
}
.controls button svg {
  position: absolute;
  margin: auto;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 26px;
  width: 100%;
}
.controls button.toggle svg {
  height: 20px;
}
.controls .volume {
  display: block;
  @media(max-width: 590px) {
    display: none;
  }
  float: left;
  width: 120px;
  height: 40px;
  position: relative;
  margin-right: 0;
}
.controls .volume .current-volume {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  & > span {
    display: block;
    position: absolute;
    width: 2px;
    background-color: #5d5d5d; /* from fm4; oe1: a5a5a5 */
    &.volume-bar-25 {
      left: 13px;
      top: 18px;
      height: 4px;
    }
    &.volume-bar-50 {
      left: 17px;
      top: 16px;
      height: 8px;
    }
    &.volume-bar-75 {
      left: 21px;
      top: 14px;
      height: 12px;
    }
    &.volume-bar-100 {
      left: 25px;
      top: 12px;
      height: 16px;
    }
  }
}

.controls .volume:hover,
.controls .volume:focus-within {
  background-color: var(--controls-background-hover-color);
  z-index: 30;
  .current-volume > span {
    @mixin theme {
      background-color: var(--slider-track-color);
    }
  }
}

.controls {
  .volume-100 .current-volume span.volume-bar-25,
  .volume-100 .current-volume span.volume-bar-50,
  .volume-100 .current-volume span.volume-bar-75,
  .volume-100 .current-volume span.volume-bar-100 {
    @mixin theme {
      background-color: var(--controls-button-color);
    }
  }
  .volume-75 .current-volume span.volume-bar-25,
  .volume-75 .current-volume span.volume-bar-50,
  .volume-75 .current-volume span.volume-bar-75 {
    @mixin theme {
      background-color: var(--controls-button-color);
    }
  }
  .volume-50 .current-volume span.volume-bar-25,
  .volume-50 .current-volume span.volume-bar-50 {
    @mixin theme {
      background-color: var(--controls-button-color);
    }
  }
  .volume-25 .current-volume span.volume-bar-25 {
    @mixin theme {
      background-color: var(--controls-button-color);
    }
  }
}

.controls .volume .volume-slider {
  display: block;
  float: left;
  height: 20px;
  padding: 10px 10px 10px 0;
  position: absolute;
  left: 40px;
  width: 70px;
  input {
    opacity: 0;
    &:focus {
      opacity: 1;
    }
  }
}
.controls .volume:hover,
.controls .volume:focus-within {
  .volume-slider input {
    opacity: 1;
  }
}
.controls .volume .mute-toggle {
  height: 40px;
  width: 40px;
  float: left;
}
.controls > .oon-player-flash {
  text-align: center;
  height: 40px;
  vertical-align: bottom;
  float: left;
  width: 40px;
}

.controls .oon-player-flash.rendered object {
  width: 0;
  height: 0;
  visibility: hidden;
}

.controls > * {
  margin-right: var(--button-spacing);
}


@keyframes pulse {
  0% {opacity: 1}
  50% {opacity: 0.5}
  100% {opacity: 1}
}

/* could also use aria-pressed instead of playing */
.controls:not(.live) button.toggle,
.controls.live.live-time-shift button.toggle {
  /* only show pause */
  &.playing svg > .play, &.playing svg > .stop {
    display: none;
  }
  /* only show play */
  &.paused svg > .pause, &.paused svg > .stop {
    display: none;
  }
}
.controls.live:not(.live-time-shift) button.toggle {
  /* only show stop */
  &.playing svg > .play, &.playing svg > .pause {
    display: none;
  }
  /* only show play */
  &.paused svg > .stop, &.paused svg > .pause {
    display: none;
  }
}

/* mobile player */
.controls div.mobile {
  display: none;
}
.controls audio {
  /* position audio element absolute, otherwise on ios it breaks layout */
  position: absolute;
  display: none;
}

/* style input[type=range]
  see https://www.quirksmode.org/blog/archives/2015/11/styling_and_scr.html
*/
.volume-input {
  display: block;
  width: 100%;
  height: 20px;
  -webkit-appearance: none;
  background: none;
  padding: 0;
  margin: 0;
}
.volume-input::range-track {
  height: 4px;
  width: 100%;
  color: transparent; /* ie */
  border: 0 none; /* ie */
  @mixin theme {
    background-color: var(--slider-track-color);
  }
}

.volume-input::range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 4px;
  position: relative;
  top: -8px;
  border: 0 none; /* for ff */
  border-radius: 0; /* for ff */
  @mixin theme {
    background-color: var(--controls-button-color);
  }
}

.volume-input::range-lower {
  height: 4px;
  @mixin theme {
    background-color: var(--slider-fill-color);
  }
}

.volume-input::-ms-tooltip {
   display: none;
}
</style>