<template>
  <div :id="'broadcast-item-'+item.id" 
      :class="['broadcast-item', 'type-'+item.type, 
        {'has-title': item.title || item.interpreter, 'scheduled': item.state === 'S', 'playing': item.state === 'P',
        'is-music': isMusic, 'is-LSG': isLSG, 'is-current': isCurrent, 'in-stream-start-range': item.isInStreamStartRange }]" 
      :data-type="item.type" 
      :style="{
        left: left(item)+'px',
        width: width(item)+'px',
      }">
    <a class="info" :href="link" tabindex="-1" v-if="item.title || item.interpreter" @click="onClick"  data-omac-id="timeline-item">
      <template v-if="item.duration > 1000*60*TEXT_REPEAT_DURATION_MINUTES">
        <template v-for="n in Math.round(item.duration/1000/60/TEXT_REPEAT_DURATION_MINUTES)">
          <div :key="n" :style="{position: 'absolute', 'padding-left': 'inherit', left: (n-1)*TEXT_REPEAT_DURATION_MINUTES*60/PIXEL_RATIO + 'px'}">
            <span>{{ item.title || "&nbsp;" }}</span>
            <span>{{ item.interpreter || item.subtitle }}</span>
          </div>
        </template>
      </template>

      <template v-else >
        <span>{{ item.title || "&nbsp;" }}</span>
        <span>{{ item.interpreter || item.subtitle }}</span>
      </template>
    </a>
  </div>
</template>

<script>
import config from "../config";

export default {
  name: 'broadcast-item',
  props: {
    broadcast: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    wrapperStart: Number,
    wrapperEnd: Number,
    duration: Number,
    handleWidth: Number,
    isCurrent: Boolean,
    isInLiveMode: Boolean,
    liveTimeshiftAvailable: Boolean,
    isStationPlayer: Boolean,
  },
  computed: {
    isSong() {
      return this.item.type && this.item.type.startsWith('M');
    },
    isDJSet() {
      return this.item.type === 'DJ';
    },
    isMusic() {
      return this.isSong || this.isDJSet;
    },
    isLSG() {
      return this.isSong;
    },
    link() {
      const baseHref = this.$router ? this.$router.options.base : config.RADIOTHEK_URL
      if (this.isPlayableOnDemand && (this.isInLiveMode || !this.isLSG)) {
        return baseHref + (this.isStationPlayer ? "" : this.broadcast.station + "/") + `${this.broadcast.broadcastDay}/${this.broadcast.programKey}/${this.item.start}`
      }
      return undefined
    },
    isPlayableOnDemand() {
      const item = this.item
      return (item.state === 'C' || item.state === 'P') && this.broadcast.isOnDemand && item.start < Date.now() - config.LOOPSTREAMER_READY_DELAY;
    },
  },
  methods: {
    onClick(ev) {
      // old player also had touch handler, why? (maybe because of click delay?)
      if (this.isInLiveMode && !this.liveTimeshiftAvailable) {
        if (this.$router) {
          ev.preventDefault();
          ev.stopPropagation();
          const path = ev.currentTarget.getAttribute('href').replace(this.$router.options.base, '/') // why is '/' necessary?
          this.$router.push(path);
        }
      } else {
        ev.preventDefault()
        ev.stopPropagation()
        if (this.broadcast.isOnDemand) {
          this.$emit("clicked", this.item)
        }
      }
    },
    left(item) {
      return (Math.max(this.wrapperStart, item.start)-this.wrapperStart)/1000/config.PIXEL_RATIO
    },
    width(item) {
      const width =  (Math.min(this.wrapperEnd, item.end)-item.start)/1000/config.PIXEL_RATIO
      console.assert(width >= 0)
      return width
    },
  },
  data() {
    return {
      PIXEL_RATIO: config.PIXEL_RATIO, // how much pixels for second; TODO make filter or so
      TEXT_REPEAT_DURATION_MINUTES: 30,
    }
  }

}
</script>

<style lang="postcss" scoped>

@import "../styles/vars.css";
@import "../styles/mixins.css";

.broadcast-item {
  /* fallback broadcast item color, not used because items without titles are not displayed */
  color: white;
  background-color: #414141; 
  border-color: var(--streams-background-color);
  height: 100%;
  box-sizing: content-box;
  border-left-width: 0px;
  border-right-width: 0px;
  border-left-style: solid;
  border-right-style: solid;
  overflow: hidden;
  z-index: 20;
  transition-property: opacity;
  transition-duration: 1s;
  font-family: 'ORF ON Condensed';
  font-weight: 500;
  font-size: 14px;
  box-sizing: content-box;
}
.broadcast-item .info {
  box-sizing: border-box;
  width: 100%;
  min-width: 100%; /* keep if changing to auto for :hover */
  height: 100%;
  display: block;
  background-color: inherit;
  pointer-events: none;
  padding: 8px 3px;
  padding-right: calc(var(--titled-item-border-left-width) + 3px);
  line-height: 17px;
  color: inherit;
  position: absolute;
}

.broadcast-item .info span {
  width: 100%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis; /* js: if clientWidth != scrollWidth, then overflown */
  overflow: hidden;
}

.broadcast-item.scheduled {
  filter: brightness(50%);
  z-index: 19;
}
/* something like this could create some kind of fuzzy boundaries */
/*
.broadcast.scheduled:after {
box-shadow: -15px 0 15px -15px #363839 inset;
content: " ";
height: 100%;
left: -15px;
position: absolute;
top: 0;
width: 15px;
}

.broadcast.scheduled:before {
box-shadow: 15px 0 15px -15px #363839 inset;
content: " ";
height: 100%;
right: -15px;
position: absolute;
top: 0;
width: 15px;
}
*/
.broadcast-item a.info[href] {
  pointer-events: auto;
}

.broadcast-item.is-music {
  background-color: var(--song-background-color);
  color: black;
}
.broadcast-item.is-LSG {
  a.info {
    transition-property: opacity;
    transition-duration: 0.3s;
    opacity: 0;
  }
}

/* feature items in brand color, except "newsy" */
.broadcast-item.has-title[data-type="B"],
.broadcast-item.has-title[data-type="BP"],
.broadcast-item.has-title[data-type="BL"],
.broadcast-item.has-title[data-type="BP"] {
  @mixin theme {
    background-color: var(--featured-element-background-color);
    color: var(--featured-element-text-color);
    z-index: 20;
    &:hover {
      .info {
        background-color: var(--featured-info-hover-background-color);
      }
    }
  }
}


/* "newsy" items */
.broadcast-item.has-title[data-type^="N"],
.broadcast-item.has-title[data-type="BJ"],
.broadcast-item.has-title[data-type="BN"] {
  --news-background-color: #5286c1;
  background-color: var(--news-background-color);
  color: white;
  &:hover {
    .info {
      background-color: color(var(--news-background-color) alpha(0.9));
    }
  }
}

.broadcast-item.has-title[data-type="NS"] {
  --sport-color: #FFE559;
  background-color: var(--sport-color);
  color: black;
  &:hover {
    .info {
      background-color: color(var(--sport-color) alpha(0.9));
    }
  }
}

.radiothekplayer.oe3 .broadcast-item.has-title[data-type="BC"] {
  --comedy-color: #E100C8;
  background-color: var(--comedy-color);
  color: white;
  &:hover {
    .info {
      background-color: color(var(--comedy-color) alpha(0.9));
    }
  }
}

</style>
