/**
 * Changes the timezone of a given date to the local (i. e. computer of browser) timezone preserving the time of day
 * Useful if you want to use date formatting, i. e. printing the hour of the source timezone
 * Notice that the date is semantically incorrect
 * @param {Object} date the date from some other timezone
 * @param {number} offset the offset in msecs from some other timezone
 * @returns {Object} the local time
 */
export function convertToLocalTime(date, offset) {

    // TODO could use `utcToZonedTime` from 'date-fns-tz':
    // https://date-fns.org/v2.17.0/docs/Time-Zones
    // but it doesn't seem to accept timezone offset values:
    // https://github.com/marnusw/date-fns-tz/issues/58
    //
    // could also use `toDate` from 'date-fns-tz' to parse ISO string "incorrectly"
    // but is a little crazy for legend ticks

    if (date === undefined || date === null) {
        return "";
    } else if (date.constructor !== Date) {
        let millis = parseInt(date, 10);
        if (Number.isNaN(millis)) {
            return "";
        }
        date = new Date(millis);
    }

    if (typeof offset !== "number") {
        console.warn("need offset!");
        return "";
    }
    // TODO it would be better not return this "wrong" date, but to return a formatted string
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000 - offset);
}

