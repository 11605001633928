import Vue from 'vue'
import TimelinePlayer from "./components/TimelinePlayer"

// Vue.config.productionTip = false

export default function renderEmbeddedTimeline(els) {
    Array.prototype.forEach.call(els, function (el) {
      // RG says oe3 is new cluster, everything else old
      const host = el.dataset["station"] === "oe3" ? "audioapi2" : "audioapi"
      const url = "https://" + host + ".orf.at/" + el.dataset["station"] + "/api/json/4.0/" + el.dataset["path"] + "?_origin=" + location.origin;

      const req = new XMLHttpRequest();
      req.open('GET', url);
      req.responseType = 'json';
      req.send();
      //        utils.createSessionCookieIfNotExisting();
      req.onload = function () {
        if (req.status == 200) {
            const json = req.responseType == 'json' ? req.response : JSON.parse(req.responseText)
            const mountEl = document.createElement("div")
            el.appendChild(mountEl)
            new Vue({
              render: function (createElement) {
                return createElement(TimelinePlayer, { props: 
                  { 
                    broadcasts: [json], 
                    loopstreamChannel: json["station"],
                    isEmbed: true,
                    markIn: json["markIn"],
                    // should probably also pass markIn
                  },
                  // class: "embed",
                })
              },
              el: mountEl
            })
        } else {
          // 404
          el.classList.add("error");
          // in item player, it's 'Audio aus <a href="https://orf.at/stories/verfuegbarkeit/">rechtlichen Gründen</a> nicht mehr verfügbar.
          el.innerHTML = req.status == 410 ? "Dieser Beitrag ist leider nicht mehr verfügbar. Inhalte der ORF-Radiothek können aus <a href='https://orf.at/stories/verfuegbarkeit/'>rechtlichen Gründen</a> nur sieben Tage angehört werden." : "Sendung wurde nicht gefunden.";
        }
      };
      
      req.onerror = function () {
        // network error
        el.classList.add("error");
        el.textContent = "Sendung konnte nicht geladen werden.";
      }
  })
}
