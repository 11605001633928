<template>
  <div id="timeline-view" :class="['timeline-player', {'live': isInLiveMode, 'show-broadcast-image': isEmbed, 'embed': isEmbed, 'live-timeshift-enabled': liveTimeshiftAvailable}]" v-if="broadcasts">
    <div class="current-broadcast-caption" v-if="showTitle || isEmbed" :style="{'background-image': isEmbed && currentBroadcastImageSrcCSSUrl}">
      <h3 class="mode">
        <span class="live"><span>jetzt</span><span class="program-title station-specific oe1"></span>
          <span class="station-specific fm4 oe3" lang="en">live</span>
        </span>
        <span class="ondemand">
          <span class="station-specific oe1">Sie h&#246;ren</span><span class="program-title station-specific oe1"></span>
          <span class="station-specific oe3 fm4">7 Tage</span>
        </span>
      </h3>
      <h2 class="title"><a :href="currentBroadcastHref">{{ currentBroadcast.title }}</a></h2>
      <span class="date">{{ currentBroadcastFormattedDate }}
        <template v-if="showDebug">    Pos: {{ new Date(playingPosition).toTimeString() }} 
        Item: {{ currentBroadcastItem ? currentBroadcastItem.title : "-"}} digest: {{ this.propDigest}}</template>
      </span>
    </div>
    <div class="timeline-panel">
      <div class="timeline-container">
        <div :class="['timeline', 'expanded']" ref="timeviewport" aria-hidden="true" @wheel="onWheelListener" @scroll="onScrollListener" style="visibility: visible;">
          <div class="handle" ref="handle" :style="{width: cachedHandleWidth + 'px'}">
            <div class="broadcasts">
              <broadcast v-for="broadcast in broadcasts" :broadcast="broadcast" :items="filterItemsToRender(broadcast)" :key="broadcast.programKey" :wrapperStart="Math.max(broadcast.start, start)" :wrapperEnd="Math.min(broadcast.end, end)" :isInLiveMode="isInLiveMode" :liveTimeshiftAvailable="liveTimeshiftAvailable" :duration="duration" :handleWidth="cachedHandleWidth" :currentBroadcastItem="currentBroadcastItem" :isStationPlayer="isStationPlayer"
                  :style="[{
                    left: (Math.max(broadcast.start, start)-start)/1000/PIXEL_RATIO + 'px',  
                    width: (Math.min(broadcast.end, end)-Math.max(broadcast.start, start))/1000/PIXEL_RATIO + 'px'
                  }]">
              </broadcast>
            </div>
            <div class="timeline-scale" ref="timeScale">
              <template v-for="tick in legendTicks">
                <div class="tick" :style="{left: (tick.time-start)/1000/PIXEL_RATIO + 'px'}" 
                    :key="tick.time"></div>
                <div class="label" :style="{left: (tick.time-start)/1000/PIXEL_RATIO + 'px'}" v-if="tick.showLabel" :key="tick.time + '-label'">{{ formattedTick(new Date(tick.time)) }}</div>
              </template>
            </div>
            <div class="timeline-streams" ref="timeLegend" v-on="{pointermove: onTimeLegendPointerMove, click: onTimeLegendClick}">

              <div class="streams" ref="streams">
                <template v-if="isInLiveMode">
                  <div class="stream"
                    :style="{
                      left: (this.liveTimeshiftStart - this.start)/1000/PIXEL_RATIO+'px',
                      width: (this.liveTimePosition - this.start - LOOPSTREAMER_READY_DELAY)/1000/PIXEL_RATIO+'px',
                    }">
                  </div>
                </template>
                <template v-else>
                  <template v-for="broadcast in broadcasts" >
                    <div class="stream" v-for="stream in broadcast.streams" :key="stream.alias"
                      :style="{
                        left: (stream.start-broadcast.start)/1000/PIXEL_RATIO+'px', 
                        width: (Math.min(end, stream.end)-stream.start)/1000/PIXEL_RATIO+'px',
                      }">
                    </div>
                  </template>
                </template>
              </div>

              <div class="needle hover invisible" ref="hoverNeedle">
                <div class="line"></div>
                <div class="triangle">
                  <svg class="icon icon-triangle"><use xlink:href="#icon-triangle"></use></svg>
                </div>
              </div>

              <div :class="['needle', 'actual', {inactive: isInLiveMode && playerPaused}]" ref="currentNeedle" v-on="{pointerdown: onCurrentNeedlePointerDown, pointermove: onCurrentNeedlePointerMove, pointerup: onCurrentNeedlePointerUp}">
                <div class="line"></div>
                <div class="triangle">
                  <svg class="icon icon-triangle"><use xlink:href="#icon-triangle"></use></svg>
                </div>
              </div>
            </div>

            <div class="previous-broadcast broadcast-link" v-if="previous">
              <a :href="previous.href" tabindex="-1" @click="handleRoute" data-omac-id="broadcast-link-previous">{{ previous.title }}</a>
            </div>

            <div class="next-broadcast broadcast-link" v-if="next">
              <a :href="next.href" tabindex="-1" @click="handleRoute" data-omac-id="broadcast-link-next">
                <span>{{ next.title }}</span>
                <svg class="icon icon-arrow-right"><use xlink:href="#icon-arrow-right"></use></svg>
              </a>
            </div>

          </div>
        </div>
        <div class="cover left"></div>
        <div class="cover right"></div>
      </div>
      <AudioPlayer ref="audioplayer" :isLive="isInLiveMode" :liveTimeshiftAvailable="liveTimeshiftAvailable" :isLiveTimeShift="isLiveTimeShift" :cue="cue" :markOut="markOut" :endTime="end" :broadcast="currentBroadcast" :livestreamURL="livestreamURL" :livestreamQuality="livestreamQuality" :timelineStart="liveTimeshiftStart" :loopstreamHost="loopstreamHost" :loopstreamChannel="loopstreamChannel" :autoplay="autoplay" @progress="onPlayerProgress" @markOutReached="$emit('status', 'finish')" @endReached="$emit('status', 'finish')" @paused="$emit('status', $event ? 'pause' : 'play'); playerPaused = $event" @progress-ugly="/* could also do ugly: playingPosition = $event */" />
      <a class="live-link" v-if="(!isInLiveMode || isLiveTimeShift) && liveLink" :href="liveLink.href" @click="handleLiveLinkClick" data-omac-id="timeline-live-link">{{ liveLink.stationName }} <span class="live-inline">live</span> <br>hören</a>
    </div>
    <div v-html="require('!html-loader!../assets/svg-icons.svg')" style="display: none"></div>
  </div>
</template>


<style lang="postcss" scoped>

@import "../styles/mixins.css";
@import "../styles/vars.css";

>>> a {
  text-decoration: none;
}

:root {
  --sibling-broadcast-link-background-color: var(--streams-background-color);
}

.timeline-player {
  position: relative;
  clear: both;
}

.timeline-player .current-broadcast-caption {
  padding: 8px 13px 5px;
  position: absolute;
  bottom: 100%;
  max-width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: rgba(0, 0, 0, 0.23);
  color: white;
  @media (max-width: 700px) {
    padding: 5px 7px 3px;
  }
}

.timeline-player .current-broadcast-caption h2 {
  font-size: 24px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 26px;
  @media (--calendar-full-width) { /* TODO define mq */
    margin-top: 0;
    font-size: 20px;
  }
}
.timeline-player .current-broadcast-caption .mode {
  font-weight: normal;
  margin: 0;
  font-size: 1em;
  margin-bottom: 4px;
  font-family: "ORF ON Condensed SC";
  @media(max-width: 700px) {
    margin-bottom: 2px;
  }
  @media (--calendar-full-width) {
    margin-bottom: 0px;
    font-size: 14px;
  }
}
.timeline-player .current-broadcast-caption .mode {
  .live {
    display: none
  }
  .ondemand {
    display: block;
  }
}
.timeline-player.live .current-broadcast-caption .mode {
  .live {
    display: block;
  }
  .ondemand {
    display: none;
  }
}
.timeline-player .current-broadcast-caption .title {
  display: inline;
  font-weight: bold;
}
.timeline-player .current-broadcast-caption .title a {
  color: inherit;
}
.timeline-player .current-broadcast-caption .date {
  font-size: 15px;
  margin-left: 0.2em;
  font-variant-numeric: tabular-nums;
  &:empty {
    display: none; /* weird chrome bug */
  }
  @media (--calendar-full-width) {
    font-size: 14px;
  }
}

.timeline-player .current-broadcast-caption {
  h2, .date {
    font-style: italic;
  }
}

.timeline-player .current-broadcast-caption .title {
  font-family: "ORF ON Head";
  font-style: italic;
  /* fm4 also had: */
  /*
  -moz-font-feature-settings: "ss04";
  -webkit-font-feature-settings: "ss04";
  font-feature-settings: "ss04";
  */
}

.timeline-panel {
  position: relative;
  clear: both;
  background-color: var(--timeline-background-color);
  overflow: hidden; /*bfc*/
}



.timeline-container {
  height: 92px;
  overflow: hidden;
  position: relative;
  font-size: 12px;
  line-height: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.timeline {
  height: 141px; /* heigher to clip scroll bar */
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
}

.timeline >>> div {
  position: absolute;
}

.timeline-container .cover {
  pointer-events: none;
  height: 100%;
  width: 10px;
  position: absolute;
  top: 0;
  z-index: 50;
  /* inset should be -10px, but larger arbitrary values to fix safari bug: https://bugs.webkit.org/show_bug.cgi?id=209930 */
  &.left {
    box-shadow: inset var(--timeline-background-color) 10px 0 10px -15px;
  }
  &.right {
    right: 0;
    box-shadow: inset var(--timeline-background-color) -10px 0 10px -12px;
  }
}

.timeline .handle {
  height: 100%;
}

.timeline.expanded .handle {
  /* ie has no grab cursor, use image and move fallback */
  cursor: url(https://www.gstatic.com/ui/v1/icons/mail/images/2/openhand.cur), move;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab;
}

.timeline.expanded .handle.dragging {
  cursor: url(https://www.gstatic.com/ui/v1/icons/mail/images/2/closedhand.cur), move;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}


.timeline-streams {
  cursor: pointer;
  width: 100%;
}
.streams {
  width: 100%;

  .timeline-player.live:not(.live-timeshift-enabled) & {
    cursor: default;
  }
}

.stream {
  height: 35px;
  background-color: rgba(1, 1, 1, 0); /* ie10 needs background, otherwise elements below come through (time labels) */
}

/* show full width bg on live, but only for loopstreamer stream ondemand */
.timeline-player.live .streams::after,
.timeline-player:not(.live) .stream::after {
  content: "";
  width: 100%;
  background-color: var(--streams-background-color);
  height: 57px;
  position: absolute;
  top: 35px;
  pointer-events: none;
}

.timeline-scale {
  color: #d0d0d0; /* oe1: D2D5DD; fm4: white; CB: #88898C; or same as tick? */
  font-feature-settings: "tnum";
  .label {
    margin-top: 12px;
    line-height: 14px;
    width: 3em;
    margin-left: -1.5em;
    text-align: center;
  }

  .tick {
    width: 2px;
    background-color: #a5a5a5; /* from oe1, fm4; CB: #88898C */
    height: 6px;
  }

}


.timeline .handle .broadcasts {
  top: 35px;
  height: 57px;
  width: 100%;
}


/* should be in broadcast-item component, but how to get context how timeline is rendered? */
.timeline-player:not(.live) .timeline.expanded >>> .broadcast-item.is-LSG.is-current,
.timeline-player.live >>> .broadcast-item.is-LSG {
  a.info {
    opacity: 1;
  }
}

.timeline-player >>> .broadcast-item.has-title:not(.in-stream-start-range),
.timeline-player.live >>> .broadcast-item.has-title:not(:first-child) {
  border-left-width: var(--titled-item-border-left-width);
  margin-left: calc(-1 * var(--titled-item-border-left-width));
}

.timeline.expanded >>> .broadcast-item.is-music.is-current:hover,
.timeline-player.live >>>.broadcast-item.is-music:hover,
>>> .broadcast-item.has-title:hover:not(.is-music) {
  overflow: visible;
  z-index: 90 !important;
  @media (hover: none) {
    overflow: hidden;
    z-index: 20;
  }
}
>>> .broadcast-item:hover .info {
  width: auto;
  @media (hover: none) {
    width: 100%;
  }
}
>>> .broadcast-item:hover .info {
  z-index: 30;
  @media (hover: none) {
    z-index: auto;
  }
}
.timeline-player >>> .broadcast-item.is-music.is-current:hover .info,
.timeline-player.live >>> .broadcast-item.is-music:hover .info {
  background-color: color(var(--song-background-color) alpha(0.9));
  @media (hover: none) {
    background-color: inherit;
  }
}


/* ======== needle ======== */
.needle {
  position: absolute;
  height: 92px;
  z-index: 90;
  margin-left: -5px;
  width: 0;
  @mixin theme {
    &:not(.hover) {
      color: var(--link-color);
    }
  }
  border-color: currentColor;
  pointer-events: none;
  svg {
    position: absolute;
    top: 0;
    pointer-events: none; /* make sure pointer events dispatch to .triangle */
  }
}
.needle.actual .triangle {
  margin-left: -5px;
  padding-left: 5px;
  width: 20px;
  height: 35px;
  cursor: ew-resize;
  pointer-events: auto;
  background-color: rgba(1, 1, 1, 0); /* ie10 needs background, otherwise elements below come through (time labels) */
}
.timeline-player.live:not(.live-timeshift-enabled) .needle.actual {
  color: white;
}
.timeline-player.live:not(.live-timeshift-enabled) .needle.actual.inactive {
  color: rgba(166, 166, 166, 0.7);
}
.timeline-player.live:not(.live-timeshift-enabled) .needle.actual .triangle {
  cursor: default;
}
.needle.hover {
  visibility: hidden;
  opacity: 0;
  color: rgba(166, 166, 166, 0.7);
}
.streams:hover ~ .needle.hover, .needle.hover:hover {
  visibility: visible;
  opacity: 1;
  @media (hover: none) {
    visibility: hidden;
    opacity: 0;
  }
}
.needle.actual:hover ~ .needle.hover, .needle.hover.invisible, .timeline-player.live:not(.live-timeshift-enabled) .needle.hover {
  visibility: hidden !important;
  opacity: 0 !important;
}

.needle .line {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: inherit;
  position: absolute;
  top: 8px;
  bottom: 0px;
  margin-left: 5px;
  /* `!important` to override site style */
  margin-bottom: 0 !important;
}

.triangle svg {
  width: 11px;
  height: 10px;
}

/* ========= previous/next broadcast ============ */
.broadcast-link {
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 51px;
}
.broadcast-link a {
  background-color: var(--sibling-broadcast-link-background-color);
  color: white;
  line-height: 27px;
  height: 27px;
  padding: 0 8px;
  font-size: 16px;
  position: relative;
  & span {
    max-width: 300px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.broadcast-link.previous-broadcast {
  text-align: right;
  margin-right: 20px;
  right: 100%;
}
.broadcast-link.next-broadcast {
  left: 100%;
  margin-left: 30px;
  svg {
    display: none;
    width: 13px;
    height: 24px;
  }
}
.broadcast-link.next-broadcast a {
  float: left;
}
.broadcast-link.previous-broadcast a {
  float: right;
}
.broadcast-link a:after {
  background-color: transparent;
  height: 27px;
  position: absolute;
  content: "";
  /* css triangle copied from https://css-tricks.com/examples/ShapesOfCSS/ */
  border-top: 14px solid transparent;
  border-bottom: 13px solid transparent;
  display: block;
  right: -10px;
  height: 0;
  top: 0;
  width: 0;
}
.broadcast-link a:not([href]) {
  display: none;
}
.broadcast-link.next-broadcast a:after {
  @mixin theme {
    border-left: 10px solid var(--sibling-broadcast-link-background-color);
  }
  right: -10px;
}
.broadcast-link.previous-broadcast a:after {
  @mixin theme {
    border-right: 10px solid var(--sibling-broadcast-link-background-color);
  }
  left: -10px;
}

.live-link {
  position: absolute;
  right: 10px;
  padding: 4px 6px;
  bottom: 4px;
  z-index: 25;
  background: #646464;
  border-radius: 3px;
  font-family: "ORF On";
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: black;
  .live-inline {
    color: white;
  }
}



:root {
  --broadcast-image-width: 212px; /* 3:2 */
}

.embed {
  &.timeline-player {
    margin-top: 1.8em;
    margin-bottom: 1em;
  }
  &.timeline-player .current-broadcast-caption {
    right: 0;
    padding-bottom: 0;
    color: black;
    background-color: transparent;
    h2 {
      font-size: 1em;
    }
    .mode {
      display: none;
    }
  }
}

/* disable it to not load it */
@media (max-width: 1023px) {
  .timeline-player.embed.show-broadcast-image .current-broadcast-caption {
    background-image: none !important;
  }
  .date::before {
    content: "vom ";
  }
}
@media (min-width: 1024px) {
  .timeline-player.embed.show-broadcast-image {
    margin-left: var(--broadcast-image-width);
  }
  .timeline-player.embed.show-broadcast-image .current-broadcast-caption {
    color: white;
    text-align: center;
    left: calc(-1 * var(--broadcast-image-width));
    width: var(--broadcast-image-width);
    background-position: center;
    background-size: auto 100%;
    top: 0;
    height: 141px;
    padding: 106px 10px 10px 10px;
  }
  .timeline-player.embed.show-broadcast-image .current-broadcast-caption::before {
    content: "";
    position: absolute;
    height: 49px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.23);
  }
  .timeline-player.embed.show-broadcast-image .current-broadcast-caption h2 {
    margin-left: 0;
  }
  .timeline-player.embed.show-broadcast-image .current-broadcast-caption h2,
  .timeline-player.embed.show-broadcast-image .current-broadcast-caption .date {
    /* position over text */
    position: relative;
  }
}


.remote .radiothekplayer.error {
  border: 1px solid #dededf;
  background: #f4f4f5;
  box-sizing: border-box;
  color: #999;
  padding: 2em;
  text-align: center;
}

</style>

<script>
/*global MediaMetadata */
import Broadcast from "./Broadcast"
import AudioPlayer from "./AudioPlayer"
import config from "../config";

const tickDateFormatterOptions = { hour: "numeric", minute: "numeric" }
// not supported in ie, just format local time
try {
  new Intl.DateTimeFormat('de-AT', {timeZone: 'Europe/Vienna'});
  tickDateFormatterOptions.timeZone = "Europe/Vienna"
} catch (e) {
  if (e instanceof RangeError) {
    console.log("named time zones not supported:", e);
  } else {
    console.log("other error trying to create Intl.DateTimeFormat");
  }
}
const tickDateFormatter = new Intl.DateTimeFormat("de-AT", tickDateFormatterOptions)

const Dragdealer = require("dragdealer/src/dragdealer.js");

export default {
  name: 'TimelinePlayer',
  props: {
    broadcasts: {
      type: Array,
      required: true,
    },
    markIn: Number,
    markOut: Number,
    autoplay: Boolean,
    isInLiveMode: Boolean,
    previous: Object,
    next: Object,
    livestreamURL: String,
    livestreamQuality: String, // would be already in url, but use it to only change stream for same station
    liveLink: Object,
    loopstreamHost: {
      type: String,
      default: 'loopstream01.apa.at',
    },
    loopstreamChannel: {
      type: String,
      required: true,
    },
    isEmbed: Boolean,
    showTitle: Boolean,
    isStationPlayer: Boolean,
    liveTimeshiftEnabled: Boolean,
  },
  components: {
    Broadcast,
    AudioPlayer,
  },
  filters: {
  },
  methods: {
    initDragdealer() {
      console.assert(this.$refs.timeviewport)
      console.assert(!this.dragdealer)
      this.dragdealer = new Dragdealer(this.$refs.timeviewport, {
        'left': this.sliderMargin,
        'right': this.sliderMargin,
        'overflowScroll': true,
        'css3': false, /* default true makes chrome/windows render font blurry */
        //'loose': true, //creates bending effect
        'dragStartCallback': () => {
          // console.log("dragStartCallback")
          this.shouldUpdateHandle = false;
        },
        'dragStopCallback': () => { // called on pointerup/cancel
          // console.log("dragStopCallback")
          this.enableHandleUpdateAfterPause();
        }
      })
    },
    enableHandleUpdateAfterPause() {
      this.shouldUpdateHandle = false;
      clearTimeout(this.curTimeout);
      this.curTimeout = setTimeout(function () {
        this.shouldUpdateHandle = true;
        clearTimeout(this.curTimeout);
        this.curTimeout = 0;
        this.animStep();
      }.bind(this), this.DRAG_PAUSE);
    },
    onWindowResize() {
      this.timeViewportWidth = this.$refs.timeviewport.offsetWidth
    },
    onPlayerProgress(timestamp) {
      // console.log("player progress", timestamp)
      this.playingPosition = timestamp
    },
    onTimeLegendClick(e) {
      // console.log("onTimeLegendClick", e)
      // live future is hidden
      if (this.$refs.currentNeedle.contains(e.target)) {
        // this was earlier done by onlyStopPropagtion on currentNeedle
        console.log("dispatched on needle, do nothing");
        return;
      }
      console.debug('click');
      // TODO currentDuration for partially completed broadcasts
      const targetTime = (e.clientX - e.currentTarget.getBoundingClientRect().left + e.currentTarget.offsetLeft)/this.cachedHandleWidth*this.duration + this.start
      this.jumpToAbsoluteTime(targetTime);
    },
    onCurrentNeedlePointerDown(e) {
      // on direct manipulation devices, target should get pointer capture
      if (e.pointerType == "touch" && typeof e.target.hasPointerCapture === "function") {
        console.assert(e.target.hasPointerCapture(e.pointerId))
      }
      if (typeof e.target.hasPointerCapture === "function" && e.target.hasPointerCapture(e.pointerId)) {
        // console.warn("touch device, don't move needle")
        return;
      }
      if (this.isInLiveMode && !this.liveTimeshiftAvailable) {
        return;
      }
      e.currentTarget.setPointerCapture(e.pointerId);
      this.isNeedleDragging = true;
      this.dragdealer.disable();
    },
    onlyStopPropagtion(e) { //could this be done just with modifier?
      e.stopPropagation()
    },
    onTimeLegendPointerMove(e) {
      // is also done on touch device, but needle is hidden when no hover
      // could also check via pointerCapture
      if (!this.isNeedleDragging) {
        const hoverLeft = Math.min(this.cachedHandleWidth, Math.max(e.clientX - this.$refs.timeLegend.getBoundingClientRect().left, 0));
        this.$refs.hoverNeedle.style.left = hoverLeft + 'px';
        if (this.isInLiveMode && !this.liveTimeshiftAvailable) {
          this.$refs.hoverNeedle.classList.add("invisible");
        } else {
          this.$refs.hoverNeedle.classList.remove("invisible");
        }
      }
    },
    onCurrentNeedlePointerUp(e) {
      if (this.isNeedleDragging) {
        console.debug('onCurrentNeedlePointerUp');
        // TODO currentDuration for partially completed broadcasts
        const needleLeft = parseFloat(this.$refs.currentNeedle.style.left)
        const newPos = Math.min(this.cachedHandleWidth, needleLeft)/this.cachedHandleWidth*this.duration + this.start
        if (newPos >= this.getLiveTimePosition() - config.LOOPSTREAMER_READY_DELAY) {
          console.log("approached live")
          this.$refs.audioplayer.forceAutoplay = true
          this.isLiveTimeShift = false
        } else {
          if (this.isInLiveMode && this.isLiveTimeShift) {
            this.jumpToAbsoluteTime(Math.max(newPos, this.liveTimeshiftStart));          
          } else {
            this.jumpToAbsoluteTime(newPos);
          }
        }
        this.isNeedleDragging = false;
        this.dragdealer.enable();
        e.stopPropagation();
      }
    },
    onCurrentNeedlePointerMove(e) {
      if (this.isNeedleDragging) {
        const moveLeft = Math.min(this.cachedHandleWidth, Math.max(e.clientX - this.$refs.timeLegend.getBoundingClientRect().left, 0));
        const relPos = this.getLiveTimePosition() - this.start
        const percentPosition = relPos / this.duration;
        const maxLeft = Math.round(percentPosition*this.cachedHandleWidth);
        const leftRestrained = Math.min(moveLeft, this.isInLiveMode ? maxLeft : Infinity);
        this.$refs.currentNeedle.style.left = leftRestrained + "px";
        this.$refs.hoverNeedle.classList.add("invisible");
      }
    },
    onTimeLegendMouseDownTouchOnly(e) {
      //ios sometimes fires an additinal mousedown event which triggers another dragdealer drag and breaks scrolling after tap
      e.stopPropagation();
    },
    onWheelListener(e) {
      var deltaPixelFactor;
      switch (e['deltaMode']) {
        case e['DOM_DELTA_LINE']:
          // log.debug('DOM_DELTA_LINE');
          deltaPixelFactor = 16; // this should be it, but doesn't work everywhere; e.view.getComputedStyle(e.currentTarget).lineHeight; could use fontSize as approx
          //based on heuristics, a good value could be 30-40
          deltaPixelFactor = 40;
          break;
        case e['DOM_DELTA_PAGE']:
          // log.debug('DOM_DELTA_PAGE');
          deltaPixelFactor = window.innerHeight;
          break;
        case e['DOM_DELTA_PIXEL']:
          // log.debug('DOM_DELTA_PIXEL');
          deltaPixelFactor = 1;
          break;
        default:
          throw new Error("no valid delta mode");
      }
      if (Math.abs(e['deltaX']) > 0) {
        this.hasHorizontalScrollWheel = true;
        this.cancelHandleAnimation();
      }
      if (!this.hasHorizontalScrollWheel) {
        e.preventDefault();
        this.dragdealer.setValue(this.dragdealer.value.current[0]+e['deltaY']*deltaPixelFactor/this.cachedHandleWidth*2);
      }
      this.enableHandleUpdateAfterPause();
    },
    onScrollListener(e) {
      if (!this.shouldUpdateHandle) {
        // console.log("scroll while locked, continue locking longer")
        this.enableHandleUpdateAfterPause();
      } else {
        // console.log("scroll unlocked, ignore");
      }
    },
    syncHandlePosition() {
      if (!this.dragdealer.options.overflowScroll) {
        return;
      }
      // console.log("sync handle position");
      //update dragdealers internal value because it may be out of sync when doing ui scrolling
      this.dragdealer.value.current[0] = this.$refs.timeviewport.scrollLeft / this.cachedHandleWidth;
      //this cancel momentum scrolling on ios
      //android chrome already stops scrolling on next tap somewhere
      this.$refs.timeviewport.style.overflowX = "hidden";
      // on ios 13, this must really be next frame, so 2 rafs are needed
      requestAnimationFrame(() => {
        requestAnimationFrame(() => this.animStep())
      })
    },
    jumpToAbsoluteTime(t) { // TODO is this function needed?
      this.shouldUpdateHandle = true;
      this.$refs.audioplayer.jumpToTime(t, true);
      this.animStep();
    },
    cancelHandleAnimation() {
      //stop animation by setting target value to current value
      this.dragdealer.value.target[0] = this.dragdealer.value.current[0];
    },
    getLiveTimePosition() {
      const fakeHoursOffset = 0
      const SKEW = (() => {
        if (this.livestreamURL.indexOf("//orf-live.ors-shoutcast.at/") !== -1) {
          return config.LIVESTREAM_ORS_SHOUTCAST_SKEW*1000
        } else if (this.livestreamURL.indexOf(".mdn.ors.at/out/u/") !== -1) {
          return config.LIVESTREAM_ORS_HLS_SKEW*1000
        } else {
          if (this.livestreamURL.indexOf("sf.apa.at") === -1) console.warn("should only match apa stream")
          return 0;
        }
      })();
      const liveTimePosition = Date.now() - fakeHoursOffset*60*60*1000 - SKEW;
      return liveTimePosition
    },
    animStep() {
      const snap = this.snapOnNextUpdate
      this.snapOnNextUpdate = false
      // console.log("animStep, snap?", !!snap)
      let relPos = this.playingPosition - this.start
      // TODO currentDuration for partially completed broadcasts
      let percentPosition = relPos / this.duration;
      // console.assert(percentPosition >= 0 && percentPosition <= 1);
      if (this.shouldUpdateHandle) {
        if (this.dragdealer.options.overflowScroll) {
          this.$refs.timeviewport.style.overflowX = "scroll";
        }
        this.dragdealer.setValue(percentPosition, 0, snap === true);
      }
      if (!this.isNeedleDragging) {
        this.$refs.currentNeedle.style.left = Math.round(percentPosition*this.cachedHandleWidth) + 'px';
      }
    },
    filterItemsToRender(broadcast) {
      const {streams, items} = broadcast;
      return items.filter(item => {
        const itemShouldShow = item.title && (this.isInLiveMode || item.isOnDemand);
        if (streams) {
          return itemShouldShow && streams.some(stream => {
            //if start stream range
            const inRange = item.start >= stream.start && item.start < Math.min(this.end, stream.end);
            if (inRange) {
              const startDiff = item.start - stream.start;
              item.isInStreamStartRange = startDiff <= config.BROADCASTITEMS_STREAM_ALIGN_DURATION_MS;
            }
            return inRange;
          });
        } else {
          return itemShouldShow;
        }
      })
    },
    onBroadcastItemClick(broadcastItem) {
      // old player also had touch handler, why? (maybe because of click delay?)
      if (!this.dragdealer.activity) {
        this.jumpToAbsoluteTime(broadcastItem.start)
      }
    },
    /* handle pushstate route if available */
    handleRoute(ev) {
      if (this.$router) {
        ev.preventDefault();
        ev.stopPropagation();
        const path = ev.currentTarget.getAttribute('href').replace(this.$router.options.base, '/') // why is '/' necessary?
        this.$router.push(path);
      }
    },
    handleLiveLinkClick(e) {
      if (this.isInLiveMode && this.isLiveTimeShift) {
        e.preventDefault();
        this.$refs.audioplayer.forceAutoplay = true
        this.shouldUpdateHandle = true
        this.isLiveTimeShift = false
      } else {
        this.handleRoute(e)
      }
    },
    updateLiveTimelineStartEnd() {
      const firstBroadcastItem = this.broadcasts[0].items[0]
      this.start = Math.min(firstBroadcastItem ? firstBroadcastItem.start : Infinity, Date.now() - config.LIVE_TIMELINE_MIN_PAST_DURATION)
      const lastBroadcast = this.broadcasts[this.broadcasts.length-1]
      const lastBroadcastItem = lastBroadcast.items[lastBroadcast.items.length-1]
      this.end = Math.max(lastBroadcastItem ? lastBroadcastItem.end : 0, Date.now() + config.LIVE_TIMELINE_MIN_FUTURE_DURATION)
    },
    formattedTick(date) {
      const formattedDate = tickDateFormatter.format(date);
      // not working in ie11 because start with unicode 8206, but don't care
      return formattedDate.startsWith("0") ? formattedDate.substring(1) : formattedDate
    },
  },
  computed: {
    //combination of all relevant props to reload player
    propDigest() {
      if (this.isInLiveMode) {
        return [this.broadcasts[0].station, "live"].join("_")
      } else {
        if (this.broadcasts.length !== 1) console.warn("only 1 bc supported");
        return [this.broadcasts[0].station, [this.broadcasts[0].programKey, this.broadcasts[0].broadcastDay].join("-"), this.markIn].join("_")
      }
    },
    liveTimeshiftAvailable() {
      return this.liveTimeshiftEnabled && this.currentBroadcast && this.currentBroadcast.isOnDemand;
    },
    startEndDigest() {
      return [this.start, this.end].join("_")
    },
    cachedHandleWidth() {
      // TODO currentDuration for partially completed broadcasts
      return Math.ceil(this.duration/1000/this.PIXEL_RATIO)
    },
    // currently playing broadcast in timeline if more than 1 is rendered (i.e. live)
    currentBroadcast() {
      if (this.playingPosition === 0) console.warn("should not compute current bc when playing position is not set (==0)")
      // console.log("calc current bc", this.playingPosition)
      const foundBroadcast = this.broadcasts.find(broadcast => {
        return this.playingPosition >= broadcast.start && this.playingPosition < broadcast.end
      })
      if (foundBroadcast) {
        return foundBroadcast
      } else {
        console.warn("currently playing broadcast cannot be found")
        // return latest to not crash
        return this.broadcasts[this.broadcasts.length-1]
      }
    },
    currentBroadcastItem() { 
      if (this.playingPosition === 0) console.warn("should not compute current item when playing position is not set (==0)")
      // console.log("calc current bci")
      // TODO could increase performance by not calculating every time, but takes 0.2ms on Mac Chrome
      const currentlyPlayingItems = this.currentBroadcast.items.filter( item => {
        return !!item.title && item.state != "S" && item.start <= this.playingPosition && item.end > this.playingPosition
      });
      return currentlyPlayingItems[currentlyPlayingItems.length - 1];
    },
    currentBroadcastModImage() {
      return this.currentBroadcast.images.find(img => img.category == "imgmod");
    },
    currentBroadcastProgramImage() {
      const realImage = this.currentBroadcast.images.find(img => img.category == "imgprog");
      return realImage || this.currentBroadcast.images.find(img => img.category == "imgprog-fallback");
    },
    currentBroadcastImageSrc() {
      if (this.currentBroadcast.station === "oe3") {
        return this.currentBroadcastModImage.versions[0].path
      } else {
        return this.currentBroadcastProgramImage.versions[0].path
      }
    },
    currentBroadcastImageSrcCSSUrl() {
      // encodeURI escapes double quote, but not single quote, so wrap in double quotes
      /*
        encodeURI("'")
        => "'"
        encodeURI('"')
        => "%22"
      */
      return 'url("' + encodeURI(this.currentBroadcastImageSrc) + '")'
    },
    currentBroadcastHref() {
      // this call doesn't have .isStationPlayer set, but doesn't matter
      return Broadcast.methods.createBroadcastLink(this.currentBroadcast)
    },
    currentBroadcastFormattedDate() {
      const date = new Date(this.currentBroadcast.niceTime)
      // return date.toLocaleString('de-AT') // "14.11.20"
      // return new Intl.DateTimeFormat('de-AT').format(date)
      return date.getDate().toString().padStart(2, '0') + "." + (date.getMonth()+1).toString().padStart(2, '0') + "."
    },
    duration() {
      return this.end - this.start
    },
    legendTicks() {

      const labelEveryMinute = 5;
      const tickEveryMinute = 1;

      const startDate = new Date(this.start)
      var startLegend = new Date(startDate);
      const startMinute = Math.ceil((startLegend.getMinutes()+1)/tickEveryMinute)*tickEveryMinute
      // console.log("legend start minute", startMinute)
      startLegend.setMinutes(startMinute);
      startLegend.setSeconds(0);
      startLegend.setMilliseconds(0);

      var endLegend = new Date(startDate.getTime() + this.end - this.start)
      endLegend.setMinutes(endLegend.getMinutes());
      endLegend.setSeconds(0);
      endLegend.setMilliseconds(0);

      const legendMinutes = (endLegend - startLegend)/1000/60 + 1
      const tickCount = Math.floor(legendMinutes/tickEveryMinute)
      // console.log("legendMinutes", legendMinutes, "tickCount", tickCount)
      const ticks =  new Array(tickCount).fill().map((val, ind) => {
        return {
          time: startLegend.getTime()+ind*1000*60*tickEveryMinute,
          showLabel: (ind*tickEveryMinute + startLegend.getMinutes()) % labelEveryMinute == 0,
        }
      })
      return ticks
    },
    sliderMargin() {
      return this.timeViewportWidth/2
    },
    liveTimeshiftStart() {
      const unavailableBroadcasts = this.broadcasts.filter(broadcast => !broadcast.isOnDemand)
      const lastUnavailableBroadcast = unavailableBroadcasts.pop()
      if (lastUnavailableBroadcast) {
        return lastUnavailableBroadcast.end
      } else {
        return this.start
      }
    }
  },
  watch: {
    playingPosition(newVal, oldVal) {
      // console.log("playing pos changed to", newVal)
      this.animStep();
      this.$emit("progress", newVal);
      // or do it this way like in old player to not make watcher always fire?
      // this.updateCurrentBroadcastItem();
      if (this.isInLiveMode && this.shouldUpdateHandle) {
        // only extend min duration if below half of it
        if (newVal > this.end - config.LIVE_TIMELINE_MIN_FUTURE_DURATION/2) {
          console.log("update live timeline end")
          this.updateLiveTimelineStartEnd();
        }
      }
    },
    shouldUpdateHandle(newVal) {
      // console.log("---- change shouldUpdateHandle to", newVal);
      if (newVal) {
        this.syncHandlePosition();
      }
    },
    isInLiveMode(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.shouldUpdateHandle = true
        this.snapOnNextUpdate = true
      }
    },
    sliderMargin() {
      this.dragdealer.options.left = this.dragdealer.options.right = this.sliderMargin;
      this.dragdealer.reflow();
    },
    cachedHandleWidth() {
      // console.log("cachedHandleWidth cahnged to", this.cachedHandleWidth)
      this.$nextTick(() => this.dragdealer.reflow())
    },
    broadcasts(n, o) {
      if (this.isInLiveMode && this.shouldUpdateHandle) {
        //update start if not scrolling
        this.updateLiveTimelineStartEnd();
      }
      // don't snap if there was a single broadcast,
      const firstBroadcastIsSame = n[0].programKey === o[0].programKey && n[0].broadcastDay === o[0].broadcastDay;
      if (!firstBroadcastIsSame) {
        // console.log("single broadcasts changed, setting snap")
        this.shouldUpdateHandle = true
        this.snapOnNextUpdate = true
      }
    },
    livestreamURL(n, o) {
      // console.log("livestream url change", o, n)
    },
    startEndDigest() {
      // console.log("start and/or end changed, snap")
      this.snapOnNextUpdate = true
      // nextTick is necessary because when changing broadcasts, playingPosition is not yet valid
      this.$nextTick(() => this.animStep())
    },
    propDigest: {
      immediate: true, // this is needed to be called on fir change from undef to something
      handler(n, o) {
        // console.log("prop digest changed", n, o)
        if (this.isInLiveMode) {
          this.isLiveTimeShift = false;
          this.updateLiveTimelineStartEnd();
        } else {
          const lastBroadcast = this.broadcasts[this.broadcasts.length-1]
          this.start = this.broadcasts[0].start
          var end = Math.min(lastBroadcast.end, Date.now() - config.LOOPSTREAMER_READY_DELAY); //loopstreams get registered up to the future//
          end = Math.min(end, lastBroadcast.streams[lastBroadcast.streams.length-1].end);
          this.end = Math.max(this.start + 1000, end); //make sure duration > 0
        }
        // console.log("prop digest change, update handle")
        this.shouldUpdateHandle = true
        if (this.isInLiveMode) {
          this.playingPosition = this.getLiveTimePosition()
        } else if (this.markIn) {
          this.playingPosition = this.markIn
        } else {
          const firstMarkIn = this.broadcasts[0].marks.find(mark => mark.type == 'in')
          this.playingPosition = firstMarkIn ? firstMarkIn.timestamp : this.start
        }
        if (!this.isInLiveMode || !this.isLiveTimeShift) {
          this.cue = this.playingPosition
        } else {
          this.cue = 0;
        }
      }
    },
    currentBroadcast: {
      immediate: true, // this is needed to be called on fir change from undef to something
      handler(newVal, oldVal) {
        if (oldVal != newVal) this.$emit("broadcastchange", newVal)
      }
    },
    currentBroadcastItem: {
      immediate: true, // this is needed to be called on fir change from undef to something
      handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        // console.log("broadcast item changed", newVal.id, oldVal.id)
        this.$emit("broadcastitemchange", newVal || null);
        // we could also set metadata, but we would have to redo document title
        // but we don't know pretty station name, etc
        // also, image is set as background over whole lock screen
        /*
        if ('mediaSession' in navigator) {
          if (newVal) {
            // sizes are needed; we don't know height so we just use 1:1 
            const images = newVal.images && newVal.images.length > 0 ? 
                newVal.images[0].versions.map(version => ({src: version.path, sizes: version.width + "x" + version.width})) : undefined;
            navigator.mediaSession.metadata = new MediaMetadata({
              title: newVal.title,
              artist: newVal.interpreter || newVal.subtitle,
              artwork: images
            });
          } else {
            // TODO program image or not?
            const images = this.currentBroadcast.images && this.currentBroadcast.images.length > 0 ? 
                this.currentBroadcast.images[0].versions.map(version => ({src: version.path, sizes: version.width + "x" + version.width})) : undefined;
            navigator.mediaSession.metadata = new MediaMetadata({
              title: this.currentBroadcast.title,
              artist: this.currentBroadcast.station,
              artwork: images
            });
          }
        }
        */
      }
    },
    start(newVal) {
      if (this.isInLiveMode && this.isLiveTimeShift) {
        if (this.playingPosition < newVal) {
          console.log("time catched up, going back to live")
          this.$refs.audioplayer.preventAutoplay = true
          this.shouldUpdateHandle = true
          this.isLiveTimeShift = false
        }
      }
    }
  },
  mounted() {
    this.initDragdealer()
    // TODO maybe use setTimeout and only reschedule if in liveMode
    const liveTimeSourceUpdater = () => {
      if (this.isInLiveMode) {
        this.liveTimePosition = this.getLiveTimePosition()
        if (!this.isLiveTimeShift) {
          this.playingPosition = this.liveTimePosition
        }
      }
    }
    liveTimeSourceUpdater()
    this.liveTimeSource = setInterval(liveTimeSourceUpdater, 1000*this.PIXEL_RATIO/3);
    addEventListener("resize", this.onWindowResize);
    this.timeViewportWidth = this.$refs.timeviewport.offsetWidth
    // this.$refs.audioplayer.printSomething("a msg from me to you")
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
    this.dragdealer.unbindEventListeners();
    clearInterval(this.liveTimeSource)
    removeEventListener("resize", this.onWindowResize);
    clearTimeout(this.curTimeout);
    console.log("destroyed timelineplayer")
  },
  data() {
    return {
      playingPosition: 0, // msecs since 1970
      liveTimePosition: 0, // same as above
      start: 0, // keep as property because we do not wann change it while scrolling
      end: 0,
      shouldUpdateHandle: true,
      snapOnNextUpdate: true,
      didDrag: false,
      cue: 0,
      hasHorizontalScrollWheel: false,
      curTimeout: 0, /* handle be a user-agent-defined integer that is greater than zero */
      PIXEL_RATIO: config.PIXEL_RATIO, // how much pixels for second; TODO make filter or so
      DRAG_PAUSE: 5000, 
      timeViewportWidth: 0, // cache DOM el width
      showDebug: false,
      playerPaused: true,
      isLiveTimeShift: false,
      LOOPSTREAMER_READY_DELAY: config.LOOPSTREAMER_READY_DELAY,
    }
  }
}
</script>
