export default {
  PIXEL_RATIO: 2.0,
  LOOPSTREAMER_READY_DELAY: 5 * 20 * 1000, /* wolfgang ptacek says 3 chunks of 20 secs; but hls on safari needs 5 chunks */
  // oe3-q2a now has ~22 secs, q1a has ~16
  LIVESTREAM_ORS_SHOUTCAST_SKEW_Q1A: 16, // angenäherte livestream verzögerung gegenüber fm;
  LIVESTREAM_ORS_SHOUTCAST_SKEW: 21, // angenäherte livestream verzögerung gegenüber fm;
  LIVESTREAM_ORS_HLS_SKEW: 29,
  LIVE_TIMELINE_MIN_FUTURE_DURATION: 10 * 60 * 1000,
  LIVE_TIMELINE_MIN_PAST_DURATION: 30 * 60 * 1000,
  CLIENT_SESSION_ID_KEY: "radiothekClientId",
  BROADCASTITEMS_STREAM_ALIGN_DURATION_MS: 6 * 1000, // titled-item-border-left-width * PIXEL_RATIO
  RADIOTHEK_URL: "https://radiothek.orf.at/",
}