<template>
    <div :class="['broadcast', {'has-items': items.length > 0, 'scheduled': broadcast.state === 'S'}]">
        <a class="info" tabindex="-1" :href="isInLiveMode ? broadcastLink : undefined" @click="$parent.handleRoute">
            {{ broadcast.title }}
            <br>
            {{ broadcast.programTitle }}
        </a>
        <div class="broadcast-items">
            <broadcast-item :item="item" :broadcast="broadcast" :wrapperStart="wrapperStart" :wrapperEnd="wrapperEnd" :duration="duration" :isInLiveMode="isInLiveMode" :liveTimeshiftAvailable="liveTimeshiftAvailable" :handleWidth="handleWidth" :isCurrent="item == currentBroadcastItem" v-for="item in items" :key="item.id" :isStationPlayer="isStationPlayer" @clicked="$parent.onBroadcastItemClick"></broadcast-item>
        </div>
    </div>
</template>

<script>
import config from "../config";
import BroadcastItem from "./BroadcastItem"

export default {
  name: 'broadcast',
  components: {
    BroadcastItem,
  },
  props: {
    broadcast: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    isInLiveMode: Boolean,
    liveTimeshiftAvailable: Boolean,
    currentBroadcastItem: Object,
    isStationPlayer: Boolean,

    handleWidth: Number,
    wrapperStart: Number,
    wrapperEnd: Number,
    duration: Number,

  },
  methods: {
    createBroadcastLink(broadcast) {
      const baseHref = this.$router ? this.$router.options.base : config.RADIOTHEK_URL
      if ((broadcast.state === 'C' || broadcast.state === 'P') && broadcast.isOnDemand) {
        return baseHref + (this.isStationPlayer ? "" : broadcast.station + "/") + `${broadcast.broadcastDay}/${broadcast.programKey}`
      }
      return undefined
    },

  },
  computed: {
    broadcastLink() {
      return this.createBroadcastLink(this.broadcast)
    },
  }

}
</script>

<style lang="postcss" scoped>

@import "../styles/vars.css";
@import "../styles/mixins.css";

.broadcast-items {
  width: 100%;
  height: 100%;
}


.broadcast {
  @mixin theme {
    color: var(--broadcast-placeholder-text-color);
    background-color: var(--broadcast-placeholder-background-color);
    &.has-items {
      background-color: color(var(--streams-background-color) alpha(0.5));
    }
  }
  &.scheduled {
    filter: brightness(50%);
  }
  &:not(.has-items) .broadcast-items {
    display: none;
  }
  top: 0;
  bottom: 0;
  font-family: 'ORF ON Condensed';
  font-size: 14px;
  z-index: 2;
  .info {
    box-sizing: border-box;
    width: 100%;
    min-width: 100%; /* for width: auto when :hover */
    height: 100%;
    display: block;
    background-color: inherit;
    pointer-events: none;
    padding: 8px 3px;
    line-height: 17px;
    color: inherit;
    white-space: nowrap;
    text-overflow: ellipsis; /* js: if clientWidth != scrollWidth, then overflown */
    overflow: hidden;
    position: absolute;
  }
  .info[href] {
    pointer-events: auto;
  }
  box-sizing: content-box;
  &:not(.has-items) {
    border-left-width: var(--titled-item-border-left-width);
    margin-left: calc(-1 * var(--titled-item-border-left-width));
    border-color: var(--timeline-background-color);
    border-left-style: solid;
  }
  &.has-items .info {
    display: none;
  }
}

.broadcast:not(.has-items):hover {
  overflow: visible;
  z-index: 90;
  @media (hover: none) {
    overflow: hidden;
    z-index: 2;
  }
  .info {
    width: auto;
    @media (hover: none) {
      width: 100%;
    }
    @mixin theme {
      background-color: var(--broadcast-placeholder-info-hover-background-color);
    }
  }
}


</style>